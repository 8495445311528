import React, { useState } from 'react';
import './Contact.css';
import Breadcrumb from '../components/Breadcrumb.js';
import Footer from '../components/Footer';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        services: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        let updatedServices = [...formData.services];
        if (checked) {
            updatedServices.push(name);
        } else {
            updatedServices = updatedServices.filter(service => service !== name);
        }
        setFormData({
            ...formData,
            services: updatedServices
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add logic to handle form submission, e.g., send data to backend
        console.log(formData);
    };

    return (
        <>
        <div className='contact'>
            {/* <div className="banner">
                <h1>Contact Us</h1>
                <div className="action-btn-banner">
                    <a href="/Contact" className="text-decoration-none">Home &gt;&gt; Contact</a>
                </div>
            </div> */}
            <Breadcrumb />
            <div className='map'>
                <div className='container'>
                    <di className='row'>
                        <div className='col-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.838528738882!2d144.9532000758856!3d-37.81725097197468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1723640702555!5m2!1sen!2sin"
                             width="100%" height="450"
                            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </di>
                </div>
            </div>
            <div className="contact-info-section">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='box'>
                                <h3>Location</h3>
                                <p>121 King Street, Melbourne Victoria 3000 Australia</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='box'>
                                <h3>Phone</h3>
                                <p>(+61 3 8376 6284),<br/> (+800 2345 6789)</p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='box'>
                                <h3>Email</h3>
                                <p>info@fiercevpn.com,<br/> fiercevpn@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div className='form'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6'>
                            <div className='form-img'>
                                <img src='/Assets/contact.png' className='img-fluid' />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className="mb-3">
                                    <label for="name" className="form-label">Your Name</label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label for="email" className="form-label">Your Email</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label for="phone" className="form-label">Your Phone Number</label>
                                    <input type="tel" className="form-control" id="phone" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label for="service" className="form-label">Services</label>
                                    <select className="form-select" id='service' value={formData.services} onChange={handleChange} required>
                                        <option value="Loan">Loan</option>
                                        <option value="Mediclaim">Mediclaim</option>
                                        <option value="Life Insurance">Life Insurance</option>
                                        <option value="Vehicle Insurance">Vehicle Insurance</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-white">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="cta-container">
                <div className="cta-container-1">
                    <img src='/Assets/contact.png' />
                </div>
                <div className="cta-container-2">
                    <form onSubmit={handleSubmit} className="contact-form">
                        <h3>Name</h3>
                        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        <h3>Email</h3>
                        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                        <h3>Mobile Number</h3>
                        <input type="tel" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} required />
                        <div className="services-dropdown">
                            <h3>Services</h3>
                            <div className="custom-select">
                                <select value={formData.services} onChange={handleChange} required>
                                    <option value="Loan">Loan</option>
                                    <option value="Mediclaim">Mediclaim</option>
                                    <option value="Life Insurance">Life Insurance</option>
                                    <option value="Vehicle Insurance">Vehicle Insurance</option>
                                </select>
                                <span className="custom-arrow"></span>
                            </div>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div> */}
            
        </div>
        <Footer />
        </>
    );
};

const ContactInfoBox = ({ imageSrc, title, info }) => (
    <div className="contact-col-box">
        <div className="contact-box">
            <figure><img src={imageSrc} alt="" className="img-fluid mission-icons" /></figure>
            <h5 className="mission-counter-contact">{title}:</h5>
            <p className="mb-0 contact-us-box-p">{info}</p>
        </div>
    </div>
);

const CheckboxInput = ({ name, label, onChange }) => (
    <label>
        <input type="checkbox" name={name} onChange={onChange} />
        {label}
    </label>
);

export default Contact;
