import React, { useState, useEffect } from 'react';
import './Table.css';
import Search from './Search';
import { useNavigate, useParams } from 'react-router-dom';
import { DOWNLOAD_URL } from '../serviceAPI/userAPI';

const Table = ({
  headings,
  data,
  handleEdit,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  sortColumn,
  sortDirection,
  handleSort,
  setItemsPerPage,
  verticleOptions,
  onVerticalChange,
  onStatusChange,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [inputValue, setInputValue] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    filterData(searchTerm);
  }, [data, searchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  useEffect(() => {
    let array = []
    let array2 = []
    data.map((item) => {
      array.push({ status: item?.status })
      array2.push({ radio: item?.documentSelectedType,remarks:item?.remarks,amount:item?.amount,tenue:item?.tenue,rate:item?.rate })
    })
    setSelectedStatus(array)
    setInputValue(array2)
  }, [])
  console.log(selectedStatus)

  const filterData = (searchTerm) => {
    const newData = data.filter((item) =>
      Object.values(item).some((val) => String(val).toLowerCase().includes(searchTerm))
    );
    setFilteredData(newData);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredData(data);
  };

  const handleStatusChange = (item, value, key, index) => {
    if (value === 'login' || value === 'pickup' || value === 'disburse') {
      onStatusChange(item, value, key);
    }

    const updatedStatus = [...selectedStatus];
    updatedStatus[index].status = value;
    setSelectedStatus(updatedStatus);

    // Clear inputValue for the current item.id when status changes
    setInputValue((prev) => ({
      ...prev,
      [item.id]: [],
    }));

    // Set inputValue based on selectedStatus[index]?.status
    if (value === 'documentSelected') {
      setInputValue((prev) => ({
        ...prev,
        [item.id]: Array(inputValue[item.id]?.length || 2).fill(''), // Assuming 2 radio inputs
      }));
    } else if (value === 'login') {
      setInputValue((prev) => ({
        ...prev,
        [item.id]: [''], // Initial input value for login status
      }));
    } else if (value === 'query') {
      setInputValue((prev) => ({
        ...prev,
        [item.id]: Array(inputValue[item.id]?.length || 3).fill(''), // Assuming 3 inputs for query status
      }));
    }
  };
  console.log(selectedStatus)

  const handleInputChange = (e, index, field) => {
    const { value, type } = e.target;

    setInputValue(prev => {
      let newValues;

      if (Array.isArray(prev)) {
        newValues = [...prev];
        newValues[index] = {
          ...newValues[index],
          [field]: type === 'number' ? parseFloat(value) : value,
        };
      } else {
        newValues = {
          ...prev,
          [index]: {
            ...prev[index],
            [field]: type === 'number' ? parseFloat(value) : value,
          },
        };
      }

      return newValues;
    });
  };





  const sortedData = [...filteredData].sort((a, b) => {
    if (sortColumn) {
      const aValue = String(a[sortColumn]).toLowerCase();
      const bValue = String(b[sortColumn]).toLowerCase();
      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getVisiblePageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          id={1}
          onClick={handleClick}
          className={`page-number ${currentPage === 1 ? 'active' : ''}`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(<span key="start-ellipsis" className="ellipsis">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          id={i}
          onClick={handleClick}
          className={`page-number ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="end-ellipsis" className="ellipsis">...</span>);
      }
      pages.push(
        <button
          key={totalPages}
          id={totalPages}
          onClick={handleClick}
          className={`page-number ${currentPage === totalPages ? 'active' : ''}`}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };
  const handleSubmit = (item, index) => {
    console.log(selectedStatus[index], inputValue[index])
    if (selectedStatus[index]?.status == "documentSelected" && inputValue[index]?.radio) {
      item.document_name = inputValue[index]?.radio
      onStatusChange(item, 'documentSelected', 'status')
    } else if (selectedStatus[index]?.status == "query" && inputValue[index]?.remarks) {
      item.query = inputValue[index]?.remarks
      onStatusChange(item, 'query', 'status')
    } else if (selectedStatus[index]?.status == "senson" && inputValue[index]?.amount) {
      item.amount = inputValue[index]?.amount || 0
      item.rate = inputValue[index]?.rate || 0
      item.tenue = inputValue[index]?.tenue || 0
      onStatusChange(item, 'senson', 'status')
    }
  }
  console.log(inputValue)

  const redirectpage = (pdf) => {
    var file_path = DOWNLOAD_URL + 'public/uploads/' + pdf;
    console.log(file_path,'file')
    var a = document.createElement('A');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    a.target = '_blank'; // Open in a new tab
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

  return (
    <div>
      <div className="table-container">
        <div className='table-h'>
          <div className='table-h-c'>
            <Search searchTerm={searchTerm} handleSearch={handleSearch} clearSearch={clearSearch} />
          </div>
          <div className='table-h-c'>
           
          </div>
          <div className="show-by table-h-c">
            <label>Show by Rows:</label>
            <select value={itemsPerPage} onChange={(e) => { setItemsPerPage(parseInt(e.target.value)); setCurrentPage(1) }}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <table className='universal-table'>
          <thead>
            <tr>
              <th>S.No</th>
              {headings.map((heading, index) => (
                <th key={index} onClick={() => handleSort(heading.key)}>
                  {heading.head} {sortColumn === heading.key ? (sortDirection === 'asc' ? '▲' : '▼') : null}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData && currentData.length ? currentData.map((item, index) => (
              <tr key={index}>
                <td>{indexOfFirstItem + index + 1}</td>
                {headings && headings.map((heading, hIndex) => (
                  <td className='show-by' key={hIndex}>
                    {heading.key === 'status' ? (
                      <>
                        <select
                          value={selectedStatus[index]?.status || ''}
                          onChange={(e) => handleStatusChange(item, e.target.value, heading.key, index)}
                        >
                          <option value="">Select Status</option>
                          <option value="documentSelected">Document Select</option>
                          <option value="pickup">Pickup</option>
                          <option value="login">Login</option>
                          <option value="query">Query</option>
                          <option value="senson">Senson</option>
                          <option value="disburse">Disburse</option>
                        </select>
                        {selectedStatus[index]?.status === 'documentSelected' && (
                          <>
                            <div className="input-field">
                              <input
                                type="radio"
                                name={`radio-${index}`}
                                value="home"
                                checked={inputValue[index]?.radio === 'home'}
                                onChange={(e) => handleInputChange(e, index, 'radio')}
                              />
                              <label>Home</label>
                              <br />
                              <input
                                type="radio"
                                name={`radio-${index}`}
                                value="car"
                                checked={inputValue[index]?.radio === 'car'}
                                onChange={(e) => handleInputChange(e, index, 'radio')}
                              />
                              <label>Car</label>
                              <br />
                              <button onClick={() => handleSubmit(item, index)}>Submit</button>
                            </div>
                          </>
                        )}
                        {selectedStatus[index]?.status === 'query' && (
                          <>
                            <div className="input-field">
                              <input
                                type="text"
                                placeholder='Remarks'
                                value={inputValue[index]?.remarks || ''}
                                onChange={(e) => handleInputChange(e, index, 'remarks')}
                              />
                              <br />
                              <button onClick={() => handleSubmit(item, index)}>Submit</button>
                            </div>
                          </>
                        )}
                        {selectedStatus[index]?.status === 'senson' && (
                          <>
                            <div className="input-field">
                              <input
                                type='number'
                                placeholder='Amount'
                                value={inputValue[index]?.amount || ''}
                                onChange={(e) => handleInputChange(e, index, 'amount')}
                              />
                              <br />
                              <input
                                type='number'
                                placeholder='Rate'
                                value={inputValue[index]?.rate || ''}
                                onChange={(e) => handleInputChange(e, index, 'rate')}
                              />
                              <br />
                              <input
                                type='number'
                                placeholder='Tenue'
                                value={inputValue[index]?.tenue || ''}
                                onChange={(e) => handleInputChange(e, index, 'tenue')}
                              />
                              <br />
                              <button onClick={() => handleSubmit(item, index)}>Submit</button>
                            </div>
                          </>
                        )}
                      </>
                    ) : heading.key === 'verticalLoan' ? (
                      <select
                        value={item[heading.key] || ''}
                        onChange={(e) => onVerticalChange(item, e.target.value, heading.key)}
                      >
                        <option value="">Select Interest</option>
                        <option value="interested">Interested</option>
                        <option value="notInterested">Not Interested</option>
                      </select>
                    ) : heading.key === 'roleDisplay' ? (
                      item.category && item.category.map((Item) => (
                        <div key={Item.id}>
                          (Vertical = {Item.category_id == 2 ? 'LOAN' : Item.category_id == 4 ? 'MEDICLIAM' : Item.category_id == 5 ? 'LIFE INSURANCE' : 'VEHICLE INSURANCE'} : Role User : {Item['userRoles.username']})
                        </div>
                      ))
                    ) : heading.key === 'pdfname' ? (
                      <>
                      {item.pdfname && item.pdfname.split(',').map((pdf, idx) => (
                        <div key={idx}>

                          <a>Year: {item.categoryname.split(',')[idx]} -- </a>
                          <a href="#" onClick={() => redirectpage(pdf)}>
                            {pdf.slice(37)}
                          </a>
                          {idx < item.pdfname.split(',').length - 1 ? ', ' : ''}
                        </div>
                      ))}
                      </>
                      
                      // item.category && item.category.map((Item) => (
                      //   <div key={Item.id}>
                      //     (Vertical = {Item.category_id == 2 ? 'LOAN' : Item.category_id == 4 ? 'MEDICLIAM' : Item.category_id == 5 ? 'LIFE INSURANCE' : 'VEHICLE INSURANCE'} : Role User : {Item['userRoles.username']})
                      //   </div>
                      // ))
                    ) : (
                      item[heading.key]
                    )}
                  </td>
                ))}
                
                  <td>
                    <div style={{ padding: '0px' }} onClick={() => handleEdit(indexOfFirstItem + index)}>
                      <img className='table-edit' src='./Assets/edit.png' alt='edit' />
                    </div>
                  </td>
                
              </tr>
            )) : (
              <tr>
                <td colSpan={headings.length + 2}>No record found</td>
              </tr>
            )}
          </tbody>

        </table>
        {filteredData && (
          <div className='pagination'>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
            {getVisiblePageNumbers()}
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;