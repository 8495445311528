// src/dashboard/Dashboard.js
import React from 'react';
import './Dashboard.css'

const Dashboard = () => {
  return (
    <div className='dashboard-main'>
      <div className="dashboard">
        <h1>Dashboard...</h1>
      </div>
    </div>
  );
};

export default Dashboard;