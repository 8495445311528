import React from 'react';
import './About.css';
import Breadcrumb from '../components/Breadcrumb.js';
import Clientslideraboutpage from '../components/Clientslideraboutpage.js';
import Footer from '../components/Footer.js';

const Services = () => {
    return (
        <>
        <Breadcrumb />
        <div className='about-us'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <div className='about-us-img'>
                            <img src='/Assets/738.jpg' className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='about-us-content'>
                            <h1>Our Journey</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                galley of type and scrambled it to make a type specimen book. It has survived not only five 
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It 
                                was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker including versions of 
                                Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                galley of type and scrambled it to make a type specimen book. It has survived not only five 
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It 
                                was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker including versions of 
                                Lorem Ipsum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='vision-mission'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='vision-mission-box'>
                            <h2>Our Value</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='vision-mission-box'>
                            <h2>Our Mission</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='vision-mission-box'>
                            <h2>Our Vision</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='why-choose-us'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <div className='why-choose-us-content'>
                            <h1>Why Choose Us</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                galley of type and scrambled it to make a type specimen book. It has survived not only five 
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It 
                                was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker including versions of 
                                Lorem Ipsum.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has 
                                been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
                                galley of type and scrambled it to make a type specimen book. It has survived not only five 
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It 
                                was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                and more recently with desktop publishing software like Aldus PageMaker including versions of 
                                Lorem Ipsum.</p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='why-choose-us-img'>
                            <img src='/Assets/738.jpg' className='img-fluid'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Clientslideraboutpage />
        <Footer />
        </>
    );
};

export default Services;
