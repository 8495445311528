import React, { useEffect, useState } from 'react';
import './LifeInsurance.css';
import Table from '../components/Table';
import { getAllConsumers, getAllLifeInsConsumer, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';

const LifeInsurance = () => {
  const initialData = [
    { Name: 'John Doe', 'Mobile Number': '1234567890', Email: 'john@example.com' },
    { Name: 'Jane Smith', 'Mobile Number': '9876543210', Email: 'jane@example.com' },
    { Name: 'Samuel Green', 'Mobile Number': '5555555555', Email: 'sam@example.com' },
  ];

  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLifeInsConsumer();
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.email', head: 'Email' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'vertical', head: 'Status' }]);
  };


  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    // getConsumerData();
  };

  const handleVerticalChange = (index, newVertical) => {
    const updatedData = data.map((item, idx) =>
      idx === index ? { ...item, vertical: newVertical } : item
    );
    setData(updatedData);
  };

  return (
    <div className='lifeinsurance'>
      <div className='title-btn'>
        <h1>Life Insurance</h1>
      </div>
      {data.length > 0 && (
        <Table
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
          onVerticalChange={handleVerticalChange}
        />
      )}
    </div>
  );
};

export default LifeInsurance;
