import React, { useEffect, useState } from 'react';
import './Loani.css';
import Table from '../components/Table';
import { getAllConsumers, getAllLoanInterestedConsumer, getAllVerticle, getAllVerticleUser, updateLoanWorkingStatus } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';

const Loani = () => {
  const addToast = useToaster()
  const initialData = [
    { Name: 'John Doe', 'Mobile Number': '1234567890', Email: 'john@example.com', workingstatus: '' },
    { Name: 'Jane Smith', 'Mobile Number': '9876543210', Email: 'jane@example.com', workingstatus: '' },
    { Name: 'Samuel Green', 'Mobile Number': '5555555555', Email: 'sam@example.com', workingstatus: '' },
  ];

  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getAllLoanConsumerData();
  }, []);

  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanInterestedConsumer();
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.email', head: 'Email' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'status', head: 'Working Status' }]);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    // getConsumerData();
  };

  const handleStatusChange = async (item, newVertical, key) => {
    console.log(item, newVertical, key)
    let loanStatus = {
      status: newVertical,
      user_consumer_id: item.user_consumer_id
    }
    if (key == 'status') {
      if (newVertical == 'documentSelected') {
        loanStatus.document_name = item.document_name
      } else if (newVertical == 'query') {
        loanStatus.query = item.query
      } else if (newVertical == 'senson') {
        loanStatus.amount = item.amount
        loanStatus.rate = item.rate
        loanStatus.tenue = item.tenue
      }
      const response = await updateLoanWorkingStatus(loanStatus, addToast);
      if (response.status) {
        getAllLoanConsumerData()
      } else {
        // onClose();
      }
    }
  };

  return (
    <div className='loani'>
      <div className='title-btn'>
        <h1>Interested in Loan</h1>
      </div>
      {data.length > 0 && (
        <Table
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
          onStatusChange={handleStatusChange}
        />
      )}
    </div>
  );
};

export default Loani;
