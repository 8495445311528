import './Services.css';
import React, { useState } from "react";
import FAQ from "../components/faq";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

const Services = () => {
    
    return  (
      <>
      <Breadcrumb />
      <div className='services'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='service-box'>
                <img src='/Assets/life-insurance.png' className='img-fluid' />
                <h1 className='text-center mt-3'>Why life insurance is necessary?</h1>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='services'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-4'>
              <div className='service-box-heading'>                
                <img src='/Assets/life-insurance.png' className='img-fluid' />
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='service-box-content'>
                <h1>Why life insurance is necessary?</h1>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                <h3>Heading</h3>
                <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='services'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='service-box'>
                <img src='/Assets/life-insurance.png' className='img-fluid' />
                <div className='service-box-content'>
                  <h2>Why life insurance is necessary ?</h2>
                  <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='service-box'>
                <img src='/Assets/loan.png' className='img-fluid' />
                <div className='service-box-content'>
                  <h2>Why Loan is necessary?</h2>
                  <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='service-box'>
                <img src='/Assets/medicare.png' className='img-fluid' />
                <div className='service-box-content'>
                  <h2>Why Mediclaim is necessary?</h2>
                  <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='service-box'>
                <img src='/Assets/vehicle-insurance.png' className='img-fluid' />
                <div className='service-box-content'>
                  <h2>Why Vehicle insurance is necessary?</h2>
                  <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='ser-1'>
          <div className='ser-1-1'>
            <h1>Why life insurance is necessary ?</h1>
            <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
          </div>
          <div className='ser-1-2'>
            <img src='/Assets/lifeinsurance.png' />
          </div>
        </div>
        <div className='ser-2'>
          <div className='ser-2-2'>
                        <img src='/Assets/loan.png' />
                    </div>
                    <div className='ser-2-1'>
                        <h1>Why Loan is necessary ?</h1>
                        <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                    </div>
                </div>
                <div className='ser-3'>
                    <div className='ser-3-1'>
                        <h1>Why Mediclaim is necessary ?</h1>
                        <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                    </div>
                    <div className='ser-3-2'>
                        <img src='/Assets/mediclaim.png' />
                    </div>
                </div>
                <div className='ser-4'>
                    <div className='ser-4-2'>
                        <img src='/Assets/vehicleinsurance.png' />
                    </div>
                    <div className='ser-4-1'>
                        <h1>Why Vehicle insurance is necessary ?</h1>
                        <p>Knowing that loved ones will be financially protected can bring peace of mind to the policyholder. It reduces anxiety about unforeseen events impacting the family’s financial stability.</p>
                    </div>
                </div> */}
              <FAQ />
            </div>
            <Footer />
      </>
    )    
};

export default Services;
