// CustomScrollbar.js

import React from 'react';
import './Scrollbar.css'; // Import your custom scrollbar styles

const CustomScrollbar = () => {
  return (
    <div className="custom-scrollbar">
      {/* Your content here */}
    </div>
  );
}

export default CustomScrollbar;
