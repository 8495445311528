import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import Cookies from 'js-cookie';

const Sidebar = ({ directories }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoanDropdownOpen, setIsLoanDropdownOpen] = useState(false);
  const [isLoanDropdownOpenMed, setIsLoanDropdownOpenMed] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLoanDropdown = (event) => {
    event.preventDefault();
    setIsLoanDropdownOpen(!isLoanDropdownOpen);
  };

  const toggleLoanDropdownMed = (event) => {
    event.preventDefault();
    setIsLoanDropdownOpenMed(!isLoanDropdownOpenMed);
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';


  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLinkClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className='side-menu'>
          <img className='sidebar-logo' src='./Assets/footerlogo.png' alt='logo' />
          <Link to="/dashboard" className={`${activeTab === '/dashboard' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Dashboard</Link>
          {(user && user.role_id === 1)&& <Link to="/consumer" className={`${activeTab === '/consumer' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Consumer</Link>}
          {(user && (user.role_id === 1 || user.role_id === 2)) && (
            <div className='dropdown-container'>
              <Link to="/builder" className={`${activeTab === '/builder' && 's-btn-active'} s-btn builder-link`} onClick={handleLinkClick}>
                Builder
                <span className={`arrow ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}></span>
              </Link>
              {isDropdownOpen && (
                <div className='dropdown'>
                  <Link to="/builder/building" className={`${activeTab === '/builder/building' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Building</Link>
                </div>
              )}
            </div>
          )}
          {((user && user.role_id === 1) || (categoryId && categoryId.includes(2))) && (
            <div className='dropdown-container'>
              <Link to="/loan" className={`${activeTab === '/loan' && 's-btn-active'} s-btn loan-link`} onClick={handleLinkClick}>
                Loan
                <span className={`arrow ${isLoanDropdownOpen ? 'open' : ''}`} onClick={toggleLoanDropdown}></span>
              </Link>
              {isLoanDropdownOpen && (
                <div className='dropdown'>
                  <Link to="/loan/interested" className={`${activeTab === '/loan/interested' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Interested</Link>
                  <Link to="/loan/not-interested" className={`${activeTab === '/loan/not-interested' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Not Interested</Link>
                  <Link to="/loan/disburse" className={`${activeTab === '/loan/disburse' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Disburse</Link>
                </div>
              )}
            </div>
          )}
          {((user && user.role_id === 1) || (categoryId && categoryId.includes(4))) && (
            <div className='dropdown-container'>
              <Link to="/mediclaim" className={`${activeTab === '/mediclaim' && 's-btn-active'} s-btn loan-link`} onClick={handleLinkClick}>
                Mediclaim
                <span className={`arrow ${isLoanDropdownOpenMed ? 'open' : ''}`} onClick={toggleLoanDropdownMed}></span>
              </Link>
              {isLoanDropdownOpenMed && (
                <div className='dropdown'>
                  <Link to="/mediclaim/company" className={`${activeTab === '/loan/company' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Company</Link>
                </div>
              )}
            </div>
          )}
          {((user && user.role_id === 1) || (categoryId && categoryId.includes(5))) && (
            <Link 
              to="/lifeinsurance" 
              className={`${activeTab === '/lifeinsurance' ? 's-btn-active' : 's-btn'}`} 
              onClick={handleLinkClick}
            >
              Life Insurance
            </Link>
          )}
          {((user && user.role_id === 1) || (categoryId && categoryId.includes(6))) && (
            <Link 
              to="/vehicleinsurance" 
              className={`${activeTab === '/vehicleinsurance' ? 's-btn-active' : 's-btn'}`} 
              onClick={handleLinkClick}
            >
              Vehicle Insurance
            </Link>
          )}
          {(user && user.role_id === 1) && <Link to="/user" className={`${activeTab === '/user' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Role Manager</Link>}
          <Link to="/" className={`${activeTab === '/' && 's-btn-active'} s-btn`} onClick={handleLinkClick}>Logout</Link>
        </div>
        <div>
          <img
            className={`toggle-button ${isSidebarOpen ? 'rotated' : ''}`}
            onClick={handleSidebarToggle}
            src='/Assets/right-arrow.png'
            alt='Toggle Sidebar'
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
