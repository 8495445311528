import React, { useEffect, useState } from 'react';
import './popup-u.css';
import { addMediclaimUser, getAllMediclaimCompany, getAllMediclaimProduct, updateMediclaimUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const MediclaimPopup = ({ isOpen, onClose, fetchApi, initialData,view }) => {
  console.log(view)
  const addToast = useToaster()
  const [companyData, setCompanyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productType, setProductType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [formState, setFormState] = useState({
    formData: {
      Name: '',
      MobileNumber: '',
      Email: '',
      RadioButton: '',
      DateOfBirth: '',
      Age: '',
      Gender: '',
      RelationshipWithPolicyHolder: '',
      SumInsured: '',
      AdditionalSumInsured: '',
      NoClaimBonus: '',
      PreExistingIllness: '',
      AddOnCover: '',
      PolicyNumber: '',
      PolicyTenure: '',
      ExpiryDate: '',
      ClaimInExpiringPolicy: '',
      NomineeName: '',
      CustomerID: '',
      PolicyPlanType: '',
      poito: '',
      poifrom: '',
      Zone: '',
      PremiumAmount: '',
      PreviousPolicyNumber: '',
      PolicyIssuedDate: '',
      RenewDate: '',
      AgentName: '',
      AgentCode: '',
      AgentContactNumber: '',
      ProductName: '',
      CompanyName: ''
    },
    familyMembers: []
  });

  useEffect(() => {
    if (initialData && initialData.id) {
      setFormState({
        formData: {
          Name: initialData['user.username'] || '',
          MobileNumber: initialData['user.mobileNumber'] || '',
          Email: initialData['user.email'] || '',
          RadioButton: initialData.medicliam_type || '',
          DateOfBirth: initialData.dob && initialData.dob.slice(0,10) || '',
          Age: initialData.age || '',
          Gender: initialData.gender || '',
          RelationshipWithPolicyHolder: initialData.relationshipwithPolicyHolder || '',
          SumInsured: initialData.sumInsured || '',
          AdditionalSumInsured: initialData.additionalSumInsured || '',
          NoClaimBonus: initialData.noClaimBonus || '',
          PreExistingIllness: initialData.preExistingIllness || '',
          AddOnCover: initialData.addOnCover || '',
          PolicyNumber: initialData.policyNumber || '',
          PolicyTenure: initialData.policyTenure || '',
          ExpiryDate: initialData.expiryDate && initialData.expiryDate.slice(0,10) || '',
          ClaimInExpiringPolicy: initialData.claimInExpiringPolicy || '',
          NomineeName: initialData.nomineeName || '',
          CustomerID: initialData.customerID || '',
          PolicyPlanType: initialData.policyPlanType || '',
          poito: initialData.poito && initialData.poito.slice(0,10) || '',
          poifrom: initialData.poifrom && initialData.poifrom.slice(0,10) || '',
          Zone: initialData.zone || '',
          PremiumAmount: initialData.premiumAmount || '',
          PreviousPolicyNumber: initialData.previousPolicyNumber || '',
          PolicyIssuedDate: initialData.policyIssuedDate && initialData.policyIssuedDate.slice(0,10) || '',
          RenewDate: initialData.renewDate && initialData.renewDate.slice(0,10) || '',
          AgentName: initialData.agentName || '',
          AgentCode: initialData.agentCode || '',
          AgentContactNumber: initialData.agentContactNumber || '',
          ProductName: initialData.mediclaim_product_id || '',
          CompanyName: initialData.mediclaim_company_id || ''
        },
        familyMembers: initialData.family && initialData.family.split(',') || ''
      })
      setCompanyType(initialData.mediclaim_company_id || '')
      if(initialData.mediclaim_company_id){
        getProductData(initialData.mediclaim_company_id)
        setProductType(initialData.mediclaim_product_id)
      }
    }
  }, [initialData]);

  useEffect(() => {
    getCompanyData()
  }, []);

  const getProductData = async (id) => {
    let obj={
      mediclaim_company_id:id
    }
    const roleData = await getAllMediclaimProduct(obj);
    if (roleData?.data && roleData?.data?.length)
    setProductData(roleData?.data)
  }

  const getCompanyData = async () => {
    const roleData = await getAllMediclaimCompany();
    if (roleData?.data && roleData?.data?.length)
    setCompanyData(roleData?.data)
  }

  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    if(!view){
      e.preventDefault();
      const regex = /^\d{10}$/;
      console.log(formState.MobileNumber)
  
      if (!regex.test(formState?.formData?.MobileNumber)) {
        alert('Mobile number is invalid');
        return;
      }
      console.log(formState, 'ssssssss')
  
      const payload = {
        ...formState.formData,
        familyMembers: formState.familyMembers,
        ProductName: productType,
        CompanyName: companyType,
      };
  
      if (initialData && initialData.id) {
  
        payload.user_id=initialData.user_id;
        payload.id=initialData.id;
        const response = await updateMediclaimUser(payload, addToast);
        if (response.status) {
          fetchApi();
          onClose();
        }
      } else {
        console.log(payload,'sdsds')
        const response = await addMediclaimUser(payload, addToast);
        if (response.status) {
          fetchApi();
          onClose();
        }
      }
  
      console.log(payload)
    }
    
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        DateOfBirth: dateOfBirth,
        Age: age
      }
    }));
  };

  const handleFamilyMemberChange = (index, value) => {
    const updatedFamilyMembers = [...formState.familyMembers];
    updatedFamilyMembers[index] = value;
    setFormState(prevState => ({
      ...prevState,
      familyMembers: updatedFamilyMembers
    }));
  };

  const addFamilyMember = () => {
    setFormState(prevState => ({
      ...prevState,
      familyMembers: [...prevState.familyMembers, '']
    }));
  };

  const onChangeHandle = (e)=>{
    setProductType('');
    getProductData(e.target.value)

  }

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>{initialData ? 'Edit Entry' : 'Add Entry'}</h2>
        <form className="popup-form" onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className='mediclaim-h1'>
                <h1>Consumer Details</h1>
              </div>
              <div className='display'>
                <div className='display-one'>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    value={formState.formData.Name}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Name: e.target.value
                      }
                    }))}
                  />
                  <div>
                    <input
                      type="email"
                      name="Email"
                      placeholder="Email"
                      value={formState.formData.Email}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          Email: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
                <div className='display-two'>
                  <input
                    type="text"
                    name="MobileNumber"
                    placeholder="Mobile Number"
                    value={formState.formData.MobileNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        MobileNumber: e.target.value
                      }
                    }))}
                  />
                  <div>
                  <select
                  name="CompanyName"
                  value={companyType} onChange={(e) => {setCompanyType(e.target.value);onChangeHandle(e)}}
                  // value={formState.formData.CompanyName} // Controlled component
                  // onChange={(e) => {
                  //   setFormState(prevState => ({
                  //     ...prevState,
                  //     formData: {
                  //       ...prevState.formData,
                  //       CompanyName: e.target.value
                  //     }
                  //   }));
                  //   onChangeHandle(e);
                  // }}
                >
                  <option value="" disabled hidden>Select Company</option>
                  {companyData && companyData.map((item) => (
                    <option key={item.mediclaim_company_id} value={item.mediclaim_company_id}>
                      {item.mediclaim_company_name}
                    </option>
                  ))}
                </select>
                  </div>
                </div>
              </div>

              <div className='l-p-flex'>
                <label>
                  <input
                    type="radio"
                    name="RadioButton"
                    value="Family"
                    checked={formState.formData.RadioButton === 'Family'}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        RadioButton: e.target.value
                      }
                    }))}
                  />
                  Family
                </label>
                <span />
                <label>
                  <input
                    type="radio"
                    name="RadioButton"
                    value="Individual"
                    checked={formState.formData.RadioButton === 'Individual'}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        RadioButton: e.target.value
                      }
                    }))}
                  />
                  Individual
                </label>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className='mediclaim-h1'>
                <h1>Running Policy Details</h1>
              </div>
              {formState.formData.RadioButton === 'Family' && (
                <>
                  <div>
                    <button type="button" onClick={addFamilyMember}>Add Family Member</button>
                  </div>
                  {formState.familyMembers && formState.familyMembers.map((member, index) => (
                    <div key={index}>
                      <input
                        type="text"
                        placeholder={`Family Member ${index + 1}`}
                        value={member}
                        onChange={(e) => handleFamilyMemberChange(index, e.target.value)}
                      />
                    </div>
                  ))}
                </>
              )}
              {(formState.formData.RadioButton === 'Individual' || formState.formData.RadioButton === 'Family') && (
                <>
                  <div className='display'>
                    <div className='display-one'>
                      <label>Date of birth</label>
                      <input
                        type="date"
                        name="DateOfBirth"
                        placeholder="Date of Birth"
                        value={formState.formData.DateOfBirth}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className='display-two'>
                      <label>Age</label>
                      <input
                        type="text"
                        name="Age"
                        placeholder="Age"
                        value={formState.formData.Age}
                        readOnly
                      />
                    </div>
                  </div>
                  <label>Gender</label>
                  <div className='l-p-flex'>
                    <label>
                      <input
                        type="radio"
                        name="Gender"
                        value="Male"
                        checked={formState.formData.Gender === 'Male'}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            Gender: e.target.value
                          }
                        }))}
                      />
                      Male
                    </label>
                    <span />
                    <label>
                      <input
                        type="radio"
                        name="Gender"
                        value="Female"
                        checked={formState.formData.Gender === 'Female'}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            Gender: e.target.value
                          }
                        }))}
                      />
                      Female
                    </label>
                    <span />
                    <label>
                      <input
                        type="radio"
                        name="Gender"
                        value="Other"
                        checked={formState.formData.Gender === 'Other'}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            Gender: e.target.value
                          }
                        }))}
                      />
                      Other
                    </label>
                  </div>
                  <div className='display'>
                    <div className='display-one'>
                      <div>
                        <input
                          type="text"
                          name="RelationshipWithPolicyHolder"
                          placeholder="Relationship with Policy Holder"
                          value={formState.formData.RelationshipWithPolicyHolder}
                          onChange={(e) => setFormState(prevState => ({
                            ...prevState,
                            formData: {
                              ...prevState.formData,
                              RelationshipWithPolicyHolder: e.target.value
                            }
                          }))}
                        />
                      </div>
                      <div>
                        <input
                          type="number"
                          name="SumInsured"
                          placeholder="Sum Insured"
                          value={formState.formData.SumInsured}
                          onChange={(e) => setFormState(prevState => ({
                            ...prevState,
                            formData: {
                              ...prevState.formData,
                              SumInsured: e.target.value
                            }
                          }))}
                        />
                      </div>
                    </div>
                    <div className='display-two'>
                      <div>
                        <input
                          type="number"
                          name="NoClaimBonus"
                          placeholder="No Claim Bonus"
                          value={formState.formData.NoClaimBonus}
                          onChange={(e) => setFormState(prevState => ({
                            ...prevState,
                            formData: {
                              ...prevState.formData,
                              NoClaimBonus: e.target.value
                            }
                          }))}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="PreExistingIllness"
                          placeholder="Pre Existing Illness"
                          value={formState.formData.PreExistingIllness}
                          onChange={(e) => setFormState(prevState => ({
                            ...prevState,
                            formData: {
                              ...prevState.formData,
                              PreExistingIllness: e.target.value
                            }
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {currentStep === 3 && (
            <>
              <div className='mediclaim-h1'>
                <h1>Running Policy Details</h1>
              </div>
              <label>Period of Insurance</label>
              <div className='display'>
                <div className='display-one'>
                  <div>
                    From
                    <input
                      type="date"
                      name="poifrom"
                      value={formState.formData.poifrom}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          poifrom: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="AdditionalSumInsured"
                      placeholder="Additional Sum Insured"
                      value={formState.formData.AdditionalSumInsured}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          AdditionalSumInsured: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <label>Policy Issued Date</label>
                    <input
                      type="date"
                      name="PolicyIssuedDate"
                      placeholder="Policy Issued Date"
                      value={formState.formData.PolicyIssuedDate}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PolicyIssuedDate: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="PolicyPlanType"
                      placeholder="Policy Plan Type"
                      value={formState.formData.PolicyPlanType}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PolicyPlanType: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
                <div className='display-two'>
                  <div>
                    To
                    <input
                      type="date"
                      name="poito"
                      value={formState.formData.poito}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          poito: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="AddOnCover"
                      placeholder="Add On Cover"
                      value={formState.formData.AddOnCover}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          AddOnCover: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <label>Policy Expiry Date</label>
                    <input
                      type="date"
                      name="ExpiryDate"
                      placeholder="Expiry Date"
                      value={formState.formData.ExpiryDate}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          ExpiryDate: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <div className='mediclaim-h1'>
                <h1>Previous Policy Details</h1>
              </div>
              <div className='display'>
                <div className='display-one'>
                  <div>
                    <input
                      type="text"
                      name="PolicyNumber"
                      placeholder="Policy Number"
                      value={formState.formData.PolicyNumber}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PolicyNumber: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="PolicyTenure"
                      placeholder="Policy Tenure"
                      value={formState.formData.PolicyTenure}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PolicyTenure: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="ClaimInExpiringPolicy"
                      placeholder="Claim in Expiring Policy"
                      value={formState.formData.ClaimInExpiringPolicy}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          ClaimInExpiringPolicy: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="NomineeName"
                      placeholder="Nominee Name"
                      value={formState.formData.NomineeName}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          NomineeName: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="CustomerID"
                      placeholder="Customer ID"
                      value={formState.formData.CustomerID}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          CustomerID: e.target.value
                        }
                      }))}
                    />
                  </div>

                </div>
                <div className='display-two'>
                  <div>
                    <input
                      type="text"
                      name="Zone"
                      placeholder="Zone"
                      value={formState.formData.Zone}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          Zone: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      name="PremiumAmount"
                      placeholder="Premium Amount"
                      value={formState.formData.PremiumAmount}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PremiumAmount: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="PreviousPolicyNumber"
                      placeholder="Previous Policy Number"
                      value={formState.formData.PreviousPolicyNumber}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PreviousPolicyNumber: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>

                    <select
                      name="ProductName"
                      // value={formState.formData.ProductName}
                      value={productType} onChange={(e) => {setProductType(e.target.value);}}
                      // onChange={(e) => {
                      //   setFormState(prevState => ({
                      //     ...prevState,
                      //     formData: {
                      //       ...prevState.formData,
                      //       ProductName: e.target.value
                      //     }
                      //   }));
                      // }}
                    >
                      <option value="" disabled selected hidden>Select Product</option>
                      {productData && productData.map((item) => {
                        return (<option value={item.mediclaim_product_id} >{item.mediclaim_product_name}</option>)
                      })}
                    </select>
                  </div>
                  <div>
                    <label>Renew Date</label>
                    <input
                      type="date"
                      name="RenewDate"
                      placeholder="Renew Date"
                      value={formState.formData.RenewDate}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RenewDate: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {currentStep === 5 && (
            <>
              <div className='mediclaim-h1'>
                <h1>Agent Details</h1>
              </div>
              <div className='display'>
                <div className='display-one'>
                  <div>
                    <input
                      type="text"
                      name="AgentName"
                      placeholder="Agent Name"
                      value={formState.formData.AgentName}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          AgentName: e.target.value
                        }
                      }))}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="AgentContactNumber"
                      placeholder="Agent Contact Number"
                      value={formState.formData.AgentContactNumber}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          AgentContactNumber: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
                <div className='display-two'>
                  <div>
                    <input
                      type="text"
                      name="AgentCode"
                      placeholder="Agent Code"
                      value={formState.formData.AgentCode}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          AgentCode: e.target.value
                        }
                      }))}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="button-group">
            {currentStep > 1 && (
              <button className='med-btn' type="button" onClick={handlePrev}>Back</button>
            )}
            {currentStep < 5 ? (
              <button className='med-btn' type="button" onClick={handleNext}>Next</button>
            ) : !view && (
              <button className='med-btn' type="submit">Submit</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};


export default MediclaimPopup;
