import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addRoleUser, getCategoryById, getRoles, updateRoleUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('1');
  const [message, setMessage] = useState('');
  const [roleData, setRoleData] = useState([]);
  const addToast = useToaster()

  useEffect(() => {
    if (initialData) {
      setName(initialData.username);
      setMobile(initialData.mobileNumber); // Updated key to match backend
      setEmail(initialData.email);
      setUserType(initialData.categories); // Updated key to match backend
      getCatergoryId()
    } else {
      setName('');
      setMobile(''); // Updated key to match backend
      setEmail('');
      setUserType('');
    }
  }, [initialData]);

  useEffect(() => {
    getRoleData()
  }, []);

  const getCatergoryId = async () => {
    const Data = await getCategoryById(initialData?.user_id);
  }

  const getRoleData = async () => {
    const roleData = await getRoles();
    if (roleData?.data && roleData?.data?.length)
      setRoleData(roleData?.data)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      alert('mobile number invalid')
      return
    }

    const userData = {
      username:name.trim(),
      phone_number: mobile,
      email,
      role: parseInt(userType)
    };

    if (initialData && initialData.user_id) {
      userData.user_id = initialData.user_id
      const response = await updateRoleUser(userData,addToast);
      if (response.status) {
        setMessage('User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      const response = await addRoleUser(userData,addToast);
      if (response.status) {
        setMessage('User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    }


  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
      <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>{initialData ? 'Edit Entry' : 'Add Entry'}</h2>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='display'>
            <div className='display-one'>
                <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} required />
                <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className='display-two'>
              <input type="text" placeholder='Mobile Number' value={mobile} onChange={(e) => setMobile(e.target.value)} required />
              <select value={userType} onChange={(e) => setUserType(e.target.value)} required>
                <option value="" disabled selected hidden>Select Role</option>
                {roleData && roleData.map((item) => {
                  return (<option value={item.category_id} >{item.category_name}</option>)
                })}
              </select>
            </div>
          </div>
          <button className='dashboard-btn popup-btn' type="submit">{initialData ? 'Update' : 'Add'}</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default Popup;
