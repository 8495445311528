import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import './SocialIcons.css';

const Footer = () => {
  return (
    <>
    <div className='cta'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <form>
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-6">
                  <input type="text" className="form-control" placeholder="Name"/>
                </div>
                <div className="col-lg-3 col-md-6">
                  <input type="email" className="form-control" placeholder="Email"/>
                </div>
                <div className="col-lg-3 col-md-6">
                  <input type="tel" className="form-control" placeholder="Phone Number"/>
                </div>
                <div className="col-lg-3 col-md-6">
                <button type="submit" className="btn btn-white">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer className='footer'>
      <div className='footer-top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='footer-widget'>
                <Link to="/">
                  <img src="/Assets/footerlogo.png" className="footer-logo img-fluid" alt="Logo" />
                </Link>
                <p>We are a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do elusmod tempor incididun.</p>
                <div className='footer-info'>
                  <div className='phone'>
                    <div className='icons'>
                      <i className="fa-solid fa-phone-volume"></i>
                    </div>
                    <div className='content'>
                      <span>Have a question?</span>
                      <span>+91 9876543210</span>
                    </div>
                  </div>
                  <div className='email'>
                    <div className='icons'>
                      <i className="fa-regular fa-envelope"></i>
                    </div>
                    <div className='content'>
                      <span>Contact us at</span>
                      <span>info@domain.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='footer-widget'>
                <h3>Newsletter</h3>
                <p>Be the first one to know about discounts, offers and events weekly in your mailbox. Unsubscribe whenever you like with one click.</p>
                <div className='newsletter-form'>
                  <form className='d-flex align-items-center'>
                    <i className="fa-regular fa-envelope"></i>
                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder='Enter your Email' />
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='copyright'>
                <span>&copy; 2024. All Right Reserved.</span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='social-media'>
                <ul>
                  <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                  <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                  <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;