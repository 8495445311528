import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addConsumerUnit, addConsumerUser, updateConsumerUnit, updateConsumerUser } from '../serviceAPI/userAPI';
import Cookies from 'js-cookie';
import { useToaster } from './Toaster';

const UnitPopup = ({ isOpen, onClose, fetchApi, initialData,mainData }) => {
  const addToast = useToaster();
  const [formState, setFormState] = useState({
    Name: initialData['user.username'] || '',
    MobileNumber: initialData['user.mobileNumber'] || '',
    Email: initialData['user.email'] || '',
  });
  console.log(initialData,mainData)
  const [status, setStatus] = useState(initialData?.status || 'interested');
  const user = Cookies.get('user') && JSON.parse(Cookies.get('user')) || '';

  useEffect(() => {
    if (initialData) {
      setFormState({
        Name: initialData['user.username'] || '',
        MobileNumber: initialData['user.mobileNumber'] || '',
        Email: initialData['user.email'] || '',
      });
      setStatus(initialData.status || 'interested');
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(initialData)

    let userData
    if (status == 'interested') {
      const regex = /^\d{10}$/;
      if (!regex.test(formState.MobileNumber)) {
        alert('Mobile number is invalid');
        return;
      }
      console.log(formState,'ssssssss')
      userData = {
        username: formState.Name.trim(),
        mobileNumber: formState.MobileNumber,
        email: formState.Email,
        status: status,
        role_id: 5
      };
    } else {
      userData = {
        username: '',
        mobileNumber: '',
        email: '',
        role_id: 5,
        status: status
      };
    }

    if (initialData.builderConsumerId) {
      userData.unit_id = initialData.unit_id;
      userData.builder_id = initialData.builder_id;
      userData.office_no = initialData.office_no;
      userData.category_id = initialData.category_id
    } else {
      userData.unit_id = initialData.unitNumber.unit_id;
      userData.builder_id = initialData.unitNumber.builder_id;
      userData.office_no = initialData.unitNumber.office_no;
      userData.category_id = initialData.unitNumber.category_id
    }
    userData.builder_user_id = mainData.builder_consumer_id;
    console.log(userData, 'asdsd',initialData)
    if (initialData.builderConsumerId) {
      userData.builderConsumerId = initialData.builderConsumerId;
      userData.user_id = initialData.user_id;
      
      const response = await updateConsumerUnit(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      }
    } else {
      const response = await addConsumerUnit(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>{initialData.builderConsumerId ? 'Edit Entry' : 'Add Entry'}</h2>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='l-p-flex'>
            <label>
              <input
                type="radio"
                name="status"
                value="interested"
                checked={status === 'interested'}
                onChange={() => setStatus('interested')}
              />
              Interested
            </label>
            <span />
            <label>
              <input
                type="radio"
                name="status"
                value="notInterested"
                checked={status === 'notInterested'}
                onChange={() => setStatus('notInterested')}
              />
              Not Interested
            </label>
          </div>
          {status === 'interested' && <>
          <div className='display'>
            <div className='display-one'>
                <input
                  type="text"
                  name="Name"
                  placeholder="Name"
                  value={formState.Name}
                  onChange={(e) =>
                    setFormState({ ...formState, Name: e.target.value })
                  }
                />
              </div>
              <div className='display-two'>
                <input
                  type="text"
                  name="MobileNumber"
                  placeholder="Mobile Number"
                  value={formState.MobileNumber}
                  onChange={(e) =>
                    setFormState({ ...formState, MobileNumber: e.target.value })
                  }
                />
              </div>
            </div>
            <div>
              <input
                type="email"
                name="Email"
                placeholder="Email"
                value={formState.Email}
                onChange={(e) =>
                  setFormState({ ...formState, Email: e.target.value })
                }
              />
            </div>
          </>}
          <button className="dashboard-btn popup-btn" type="submit">
            {initialData.builderConsumerId ? 'Update' : 'Add'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UnitPopup;
