import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu.js';

const Navbar = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="/Assets/logo.png" className="logo img-fluid" alt="Logo" />
        </Link>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
          </ul>
          <div>
            <Link className="btn login-btn" to="/login">Login</Link>
          </div>          
        </div>
        <HamburgerMenu />
      </div>
    </nav>
    {/* <nav className="navbar">
      <div className="container-fluid">
        <Link className="navbar-brand" to='/'>
          <img src="/Assets/logo.png" className="logo" alt="Logo" />
        </Link>
        <div className="navbar-nav">
          <ul>
            <li className="nav-item">
              <Link className="nav-link" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        <div>
          <Link className="btn login-btn" to="/login">Login</Link>
        </div>
        <HamburgerMenu />
      </div>      
    </nav> */}
    </>
  );
}

export default Navbar;