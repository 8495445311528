import React, { useEffect, useState } from 'react';
import './Unit.css';
import UnitPopup from '../components/Unit-popup';
import { useNavigate, useParams } from 'react-router-dom';
import { getConsumerByUnit } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';

const Unit = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { id } = useParams()
  const navigate = useNavigate()
  const addToast = useToaster();

  const handleAddClick = (unitNumber) => {
    console.log(unitNumber,'unitNumber')
    setSelectedUnit(unitNumber);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedUnit(null);
  };

  useEffect(() => {
    getConsumerUnitData()
  }, [])


  const getConsumerUnitData = async () => {
    if (id) {
      let obj = {
        unit_id: id
      }
      const roleData = await getConsumerByUnit(obj, addToast);
      if (roleData?.data && roleData?.data?.length) {
        setData(roleData?.data[0]);
      }
    } else {
      navigate('/builder/building')
    }

  };

  const fetchApi = () => {
    getConsumerUnitData()
  };

  const renderUnits = (category) => {
    // console.log(category,data, 'category')
    const units = [];
    for (let i = category.from; i <= category.to; i++) {
      units.push({
        office_no: i,
        category_id: category.unit_category_id,
        unit_id: data.unit_id,
        builder_id: data.builder_id,
        builder_consumer_id:data['builderuser.user_id']

      });
    }

    // console.log(units, 'sdhsjdk')

    return units.map((unitNumber) => {
      // console.log(unitNumber,'unitNumber')
      const consumer = data.consumerList.find(
        (consumer) =>
          consumer.category_id === unitNumber.category_id &&
          consumer.office_no === String(unitNumber.office_no)
      );

      return (
        <div className='hor-column' key={unitNumber.office_no}>
          <label>{unitNumber.office_no}</label>
          <br />
          <button
            className='unit-btn'
            onClick={() => handleAddClick({ ...unitNumber, consumer })}
          >
            {consumer ? 'Edit' : 'Add'}
          </button>
          {consumer && (
            <div className='consumer-details'>
              {consumer.status === 'interested' && <>
                <p>Name: {consumer['user.username'] || ''}</p>
                <p>Email: {consumer['user.email'] || ''}</p>
                <p>Mobile: {consumer['user.mobileNumber'] || ''}</p>
              </>}
              <p>Status: {consumer.status}</p>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className='builderunit'>

      {data?.unitCategory && data?.unitCategory.map((category) => {
        return (
          <>
            <div className='category-name'>{category?.categoryName}</div>
            <div className='hor-row'>{renderUnits(category)}</div>
          </>
        );
      })}


      {isPopupOpen && (
        <UnitPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          fetchApi={fetchApi}
          initialData={selectedUnit?.consumer || { unitNumber: selectedUnit }}
          mainData={selectedUnit}
        />
      )}
    </div>
  );
};

export default Unit;
