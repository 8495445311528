import React, { useEffect, useState } from 'react';
import './Consumer.css';
import Popup from '../components/Consumer-popup';
import Table from '../components/Table';
import { getAllConsumers, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';
import Cookies from 'js-cookie';

const Consumer = () => {
  const initialData = [
    { Name: 'John Doe', 'Mobile Number': '1234567890', Email: 'john@example.com' },
    { Name: 'Jane Smith', 'Mobile Number': '9876543210', Email: 'jane@example.com' },
    { Name: 'Samuel Green', 'Mobile Number': '5555555555', Email: 'sam@example.com' },
  ];

  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const user = Cookies.get('user') && JSON.parse(Cookies.get('user')) || '';

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getConsumerData();
    getAllVerticleData();
    getAllVerticleUserData();
  }, []);

  const getConsumerData = async () => {
    const consumerData = await getAllConsumers();
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    if(user && user.role_id === 1){
      setHeading([{ key: 'username', head: 'Name' }, { key: 'email', head: 'Email' }, { key: 'mobileNumber', head: 'Mobile Number' }, { key: 'roleDisplay', head: 'Role' }]);
    }else{
      setHeading([{ key: 'username', head: 'Name' }, { key: 'email', head: 'Email' }, { key: 'mobileNumber', head: 'Mobile Number' }]);
    }
    
  };

  const getAllVerticleData = async () => {
    const verticleData = await getAllVerticle();
    if (verticleData?.data && verticleData?.data?.length) {
      setVerticle(verticleData?.data);
    } else {
      setVerticle([]);
    }
  };

  const getAllVerticleUserData = async () => {
    let categoryVerticle = { category: [4] };
    const verticleUserData = await getAllVerticleUser(categoryVerticle);
    if (verticleUserData?.data && verticleUserData?.data?.length) {
      setVerticleUser(verticleUserData?.data);
    } else {
      setVerticleUser([]);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    getConsumerData();
  };

  const handleVerticalChange = (index, newVertical) => {
    const updatedData = data.map((item, idx) =>
      idx === index ? { ...item, vertical: newVertical } : item
    );
    setData(updatedData);
  };

  return (
    <div className='consumer'>
      <div className='title-btn'>
        <h1>Consumer</h1>
        <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
        {isPopupOpen && <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />}
      </div>
      {data.length > 0 && (
        <Table
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
          onVerticalChange={handleVerticalChange}
        />
      )}
    </div>
  );
};

export default Consumer;
