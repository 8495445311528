import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addBuilderUser, addMediclaimCompany, addMediclaimProduct, updateBuilderUser, updateMediclaimCompany, updateMediclaimProduct } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';
import { useParams } from 'react-router';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster()
  const {id} = useParams()
  console.log(initialData)
  const [formValues, setFormValues] = useState({
    name: ''
  });

  useEffect(() => {
    if (initialData && initialData.mediclaim_company_id) {
      setFormValues({
        name: initialData?.mediclaim_product_name
      });
    }else{
      setFormValues({
        name: ''
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const userData = {
        mediclaim_product_name:formValues.name.trim()
    };

    userData.mediclaim_company_id = id
    if (initialData && initialData.mediclaim_product_id) {
      userData.mediclaim_product_id = initialData.mediclaim_product_id
      const response = await updateMediclaimProduct(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      const response = await addMediclaimProduct(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    }
    // addEntry(newEntry);
    // onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2>{initialData ? 'Edit Entry' : 'Add Entry'}</h2>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='display'>
              <input type="text" name="name" placeholder='Company Name' value={formValues.name} onChange={handleInputChange} required />
          </div>
          <button className='dashboard-btn popup-btn' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
