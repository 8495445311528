import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addUnitByBuilder, getAllBuilders, updateUnitByBuilder } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
    const addToast = useToaster();
    const [userType, setUserType] = useState('');
    const [builderData, setBuilderData] = useState([]);
    const [formValues, setFormValues] = useState({
        unit_name: '',
        address: '',
        ShowroomCount: 0,
        ShowroomFrom: '',
        ShowroomTo: '',
        FlatCount: 0,
        FlatFrom: '',
        FlatTo: '',
        OfficeCount: 0,
        OfficeFrom: '',
        OfficeTo: '',
        HouseCount: 0,
        HouseFrom: '',
        HouseTo: ''
    });
    const [checkboxes, setCheckboxes] = useState({
        Showroom: false,
        Flat: false,
        Office: false,
        House: false
    });

    useEffect(() => {
        getBuilderData();
    }, []);

    useEffect(() => {
        if (initialData && initialData.unit_id) {
            setFormValues({
                unit_name: initialData.unit_name,
                address: initialData.address,
                ShowroomCount: initialData.unit_showroomCount || 0,
                ShowroomFrom: initialData.unit_showroomFrom || '',
                ShowroomTo: initialData.unit_showroomTo || '',
                FlatCount: initialData.unit_flatCount || 0,
                FlatFrom: initialData.unit_flatFrom || '',
                FlatTo: initialData.unit_flatTo || '',
                OfficeCount: initialData.unit_officeCount || 0,
                OfficeFrom: initialData.unit_officeFrom || '',
                OfficeTo: initialData.unit_officeTo || '',
                HouseCount: initialData.unit_houseCount || 0,
                HouseFrom: initialData.unit_houseFrom || '',
                HouseTo: initialData.unit_houseTo || ''
            });
            setUserType(initialData.builder_id);
            setCheckboxes({
                Showroom: initialData.unit_categories?.includes('1') || false,
                Flat: initialData.unit_categories?.includes('3') || false,
                Office: initialData.unit_categories?.includes('2') || false,
                House: initialData.unit_categories?.includes('4') || false
            });
        } else {
            setFormValues({
                unit_name: '',
                address: '',
                ShowroomCount: 0,
                ShowroomFrom: '',
                ShowroomTo: '',
                FlatCount: 0,
                FlatFrom: '',
                FlatTo: '',
                OfficeCount: 0,
                OfficeFrom: '',
                OfficeTo: '',
                HouseCount: 0,
                HouseFrom: '',
                HouseTo: ''
            });
            setCheckboxes({
                Showroom: false,
                Flat: false,
                Office: false,
                House: false
            });
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: checked
        }));
        if (!checked) {
            setFormValues((prevState) => ({
                ...prevState,
                [`${name}Count`]: '',
                [`${name}From`]: '',
                [`${name}To`]: ''
            }));
        }
    };

    const getBuilderData = async () => {
        try {
            const builderData = await getAllBuilders();
            if (builderData?.data?.length > 0) {
                setBuilderData(builderData.data);
                setUserType(builderData.data[0]['builderuser.builder_id']);
            }
        } catch (error) {
            console.error('Error fetching builder data:', error);
        }
    };

    const validateCounts = () => {
        return Object.entries(checkboxes).every(([key, checked]) => {
            if (!checked) return true;

            const count = parseInt(formValues[`${key}Count`], 10);
            const from = parseInt(formValues[`${key}From`], 10);
            const to = parseInt(formValues[`${key}To`], 10);

            if (isNaN(from) || isNaN(to) || from > to || count !== (to - from + 1)) {
                return false;
            }

            return true;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateCounts()) {
            alert('Count must equal To - From + 1 for selected categories');
            return;
        }

        const userData = {
            unit_name: formValues.unit_name.trim(),
            address: formValues.address,
            builder_id: userType,
            showroomCount: checkboxes.Showroom ? formValues.ShowroomCount : 0,
            showroomFrom: checkboxes.Showroom ? formValues.ShowroomFrom : 0,
            showroomTo: checkboxes.Showroom ? formValues.ShowroomTo : 0,
            flatCount: checkboxes.Flat ? formValues.FlatCount : 0,
            flatFrom: checkboxes.Flat ? formValues.FlatFrom : 0,
            flatTo: checkboxes.Flat ? formValues.FlatTo : 0,
            officeCount: checkboxes.Office ? formValues.OfficeCount : 0,
            officeFrom: checkboxes.Office ? formValues.OfficeFrom : 0,
            officeTo: checkboxes.Office ? formValues.OfficeTo : 0,
            houseCount: checkboxes.House ? formValues.HouseCount : 0,
            houseFrom: checkboxes.House ? formValues.HouseFrom : 0,
            houseTo: checkboxes.House ? formValues.HouseTo : 0
        };

        const unitCategories = [
            { name: 'Showroom', id: 1 },
            { name: 'Flat', id: 3 },
            { name: 'Office', id: 2 },
            { name: 'House', id: 4 }
        ];

        userData.unit_categories = unitCategories
            .filter((category) => checkboxes[category.name])
            .map((category) => String(category.id));

        try {
            let response;
            if (initialData && initialData.unit_id) {
                userData.unit_id = initialData.unit_id;
                response = await updateUnitByBuilder(userData, addToast);
            } else {
                response = await addUnitByBuilder(userData, addToast);
            }

            if (response.status) {
                fetchApi();
                onClose();
            } else {
                onClose();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-btn" onClick={onClose}>&times;</span>
                <h2>{initialData ? 'Edit Entry' : 'Add Entry'}</h2>
                <div className='display'>
                    <div className='display-one'>
                        <div className='popup-form selector'>
                            <select value={userType} onChange={(e) => setUserType(e.target.value)} required>
                                <option value="" disabled selected hidden>Select Builder</option>
                                {builderData.map(item => (
                                    <option key={item['builderuser.builder_id']} value={item['builderuser.builder_id']}>
                                        {item['builderuser.company_name']}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='display-two'>
                        <form className="popup-form" onSubmit={handleSubmit}>
                            <input type="text" name="unit_name" placeholder="Building Name" value={formValues.unit_name} onChange={handleInputChange} required />
                        </form>
                    </div>
                </div>
                <form className="popup-form" onSubmit={handleSubmit}>
                    <textarea name="address" placeholder="Address" value={formValues.address} onChange={handleInputChange} required />
                    <div className='display'>
                        <div className='display-one'>
                        <div className="checkbox-container">
                                <input type="checkbox" id="showroomCheckbox" name="Showroom" checked={checkboxes.Showroom} onChange={handleCheckboxChange} />
                                <label htmlFor="showroomCheckbox">Showroom</label>
                            </div>
                            {checkboxes.Showroom && (
                                <div className="checkbox-container">
                                    <input type="number" name="ShowroomCount" placeholder="Count" value={formValues.ShowroomCount} onChange={handleInputChange} />
                                    <input type="number" name="ShowroomFrom" placeholder="From" value={formValues.ShowroomFrom} onChange={handleInputChange} />
                                    <input type="number" name="ShowroomTo" placeholder="To" value={formValues.ShowroomTo} onChange={handleInputChange} />
                                </div>
                            )}
                            <div className="checkbox-container">
                                <input type="checkbox" id="flatCheckbox" name="Flat" checked={checkboxes.Flat} onChange={handleCheckboxChange} />
                                <label htmlFor="flatCheckbox">Flat</label>
                            </div>
                            {checkboxes.Flat && (
                                <div className="checkbox-container">
                                    <input type="number" name="FlatCount" placeholder="Count" value={formValues.FlatCount} onChange={handleInputChange} />
                                    <input type="number" name="FlatFrom" placeholder="From" value={formValues.FlatFrom} onChange={handleInputChange} />
                                    <input type="number" name="FlatTo" placeholder="To" value={formValues.FlatTo} onChange={handleInputChange} />
                                </div>
                            )}
                        </div>
                        <div className='display-two'>
                        <div className="checkbox-container">
                                <input type="checkbox" id="officeCheckbox" name="Office" checked={checkboxes.Office} onChange={handleCheckboxChange} />
                                <label htmlFor="officeCheckbox">Office</label>
                            </div>
                            {checkboxes.Office && (
                                <div className="checkbox-container">
                                    <input type="number" name="OfficeCount" placeholder="Count" value={formValues.OfficeCount} onChange={handleInputChange} />
                                    <input type="number" name="OfficeFrom" placeholder="From" value={formValues.OfficeFrom} onChange={handleInputChange} />
                                    <input type="number" name="OfficeTo" placeholder="To" value={formValues.OfficeTo} onChange={handleInputChange} />
                                </div>
                            )}
                            <div className="checkbox-container">
                                <input type="checkbox" id="houseCheckbox" name="House" checked={checkboxes.House} onChange={handleCheckboxChange} />
                                <label htmlFor="houseCheckbox">Raw House</label>
                            </div>
                            {checkboxes.House && (
                                <div className="checkbox-container">
                                    <input type="number" name="HouseCount" placeholder="Count" value={formValues.HouseCount} onChange={handleInputChange} />
                                    <input type="number" name="HouseFrom" placeholder="From" value={formValues.HouseFrom} onChange={handleInputChange} />
                                    <input type="number" name="HouseTo" placeholder="To" value={formValues.HouseTo} onChange={handleInputChange} />
                                </div>
                            )}
                        </div>
                    </div>
                    <button className="dashboard-btn popup-btn" type="submit">{initialData ? 'Update' : 'Add'}</button>
                </form>
            </div>
        </div>
    );
};

export default Popup;
