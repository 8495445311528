import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer.js';
import BlogHome from './BlogHome.js';
import Breadcrumb from '../components/Breadcrumb.js';

const Blog = () => {
    return (
        <>
        <Breadcrumb />
        <div className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    </div>
                    < BlogHome />
                    <div className='col-lg-4'>
                        <div className='blog-box'>
                            <img src='/Assets/738.jpg' className='img-fluid'></img>
                            <div className='blog-box-content'>
                                <h3>Blog 3</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                <div className='blog-info'>
                                    <span>March 11,2021</span>
                                    <Link to='/bloglist3'>Read More --</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        < Footer />
        </>
    );
};

export default Blog;