import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Clientslideraboutpage.css'; 
import { animate } from "framer-motion";

const Clientslider = () => {
    const options = {
        items: 5,
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 2000,
        animateOut: 'slideOutUp',
        nav: false,
        dots: false,
        responsive: {
            0 : {
                items: 2
            },
            // breakpoint from 480 up
            480 : {
                items: 3
            },
            // breakpoint from 768 up
            768 : {
                items: 4
            },
            991 : {
                items: 5
            }
        }
    };
    return (
        <OwlCarousel className='owl-theme clientslider' {...options}>
            <div className='item'>
                <img src="/Assets/slider1.png" alt="Logo 1" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider2.png" alt="Logo 2" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider3.png" alt="Logo 3" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider4.png" alt="Logo 4" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider5.png" alt="Logo 5" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider6.png" alt="Logo 6" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider7.png" alt="Logo 7" className="img-fluid" />
            </div>                
        </OwlCarousel>
    );
};

export default Clientslider;