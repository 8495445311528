import React from 'react';
import './BlogHome.css';
import { Link } from 'react-router-dom';

const BlogHome = () => {
    return (
        <>
        <div className='col-lg-4'>
            <div className='blog-box'>
                <img src='/Assets/738.jpg' className='img-fluid'></img>
                <div className='blog-box-content'>
                    <h3>Blog 1</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <div className='blog-info'>
                        <span>March 11,2021</span>
                        <Link to='/bloglist1'>Read More --</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className='blog-box'>
                <img src='/Assets/738.jpg' className='img-fluid'></img>
                <div className='blog-box-content'>
                    <h3>Blog 2</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <div className='blog-info'>
                        <span>March 11,2021</span>
                        <Link to='/bloglist2'>Read More --</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className='blog-box'>
                <img src='/Assets/738.jpg' className='img-fluid'></img>
                <div className='blog-box-content'>
                    <h3>Blog 3</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <div className='blog-info'>
                        <span>March 11,2021</span>
                        <Link to='/bloglist3'>Read More --</Link>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className='blog'>
            <div className='a-b-m'>
                <div className='a-b-i'>
                    <img src='/Assets/738.jpg'></img>
                    <div className='blog-content'>
                        <h1>Blog 1</h1>
                        <p>No Claim Bonus: Are you a safe driver? Waht if you are told that you will be rewarded for having a safe pair of hands?</p>
                        <div className='blog-info'>
                            <span>March 11,2021</span>
                            <Link to='/bloglist1'>Read More --</Link>
                        </div>
                    </div>
                </div>
                <div className='a-b-i'>
                    <img src='/Assets/738.jpg'></img>
                    <div className='blog-content'>
                        <h1>Blog 2</h1>
                        <p>No Claim Bonus: Are you a safe driver? Waht if you are told that you will be rewarded for having a safe pair of hands?</p>
                        <div className='blog-info'>
                            <span>March 11,2021</span>
                            <Link to='/bloglist2'>Read More --</Link>
                        </div>
                    </div>
                </div>
                <div className='a-b-i'>
                    <img src='/Assets/738.jpg'></img>
                    <div className='blog-content'>
                        <h1>Blog 3</h1>
                        <p>No Claim Bonus: Are you a safe driver? Waht if you are told that you will be rewarded for having a safe pair of hands?</p>
                        <div className='blog-info'>
                            <span>March 11,2021</span>
                            <Link to='/bloglist3'>Read More --</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        </>
    );
};

export default BlogHome;