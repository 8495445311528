import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// import { auth } from '../firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import firebaseConfig from '../firebaseConfig';
import './Login.css';
import { login, loginVerfiy } from '../serviceAPI/userAPI';

const Login = () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app)
  const navigate = useNavigate();
// const mobileInputRef = useRef(null);
  const firstOtpInputRef = useRef(Array.from({ length: 6 }).map(() => React.createRef()));
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(Array.from({ length: 6 }).map(() => ''));
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      // ...
      }
    }, auth);
  };

  useEffect(() => {
    setUpRecaptcha();
  }, []);

  const handleSendOtp = async () => {
      const loggedIn = await login(mobileNumber);
      if (loggedIn) {
        setOtpVerified(true);
        navigate('/dashboard');
        // Redirect or do something after successful login
      } else {
        // Handle login failure
      }

    // const loggedIn = await loginVerfiy(mobileNumber);
    // if (loggedIn) {
    //   const phoneNumber = `+91${mobileNumber}`;
    //   const appVerifier = window.recaptchaVerifier;
    //   console.log(phoneNumber);
    //   if (!phoneNumber) {
    //     console.error("Invalid phone number");
    //     return;
    //   }

    //   if (!appVerifier) {
    //     setUpRecaptcha();
    //   }
    //   signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    //     .then((confirmationResult) => {
    //       window.confirmationResult = confirmationResult;
    //       setOtpSent(true);
    //       if (firstOtpInputRef.current[0] && firstOtpInputRef.current[0].current) {
    //         firstOtpInputRef.current[0].current.focus();
    //       }
    //     }).catch((error) => {
    //       console.error("Error sending OTP:", error);
    //     });
    // } else {
    //   alert('User not found')
    // }

  };

  const handleVerifyOtp = async () => {
    const code = otp.join('');
    window.confirmationResult.confirm(code).then(async (result) => {
      const user = result.user;
      console.log("OTP verified! User:", user);
      const loggedIn = await login(mobileNumber);
      if (loggedIn) {
        setOtpVerified(true);
        navigate('/dashboard');
// Redirect or do something after successful login
      } else {
        // Handle login failure
      }

    }).catch((error) => {
      console.error("Error verifying OTP:", error);
    });
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    if (value !== '' && index < newOtp.length - 1 && firstOtpInputRef.current[index + 1] && firstOtpInputRef.current[index + 1].current) {
      firstOtpInputRef.current[index + 1].current.focus();
    } else if (value === '' && index > 0 && firstOtpInputRef.current[index - 1] && firstOtpInputRef.current[index - 1].current) {
      firstOtpInputRef.current[index - 1].current.focus();
    }
    setOtp(newOtp);
  };

  return (
    <div className="main-login">
      <div className="login-container">
        <h2>Login with OTP</h2>
        <div className="input-group">
          <label htmlFor="phoneNumber">Mobile Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            disabled={otpSent}
          />
        </div>
        {!otpSent && (
          <button
            className="s-btn"
            id="send-otp-button"
            onClick={handleSendOtp}
            disabled={!mobileNumber || mobileNumber.length !== 10}
          >
            Send OTP
          </button>
        )}
        {otpSent && (
          <div className="otp-section">
            <div className="input-group">
              <label htmlFor="otp">OTP</label>
              <div className="otp-inputs">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    ref={firstOtpInputRef.current[index]}
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                  />
                ))}
              </div>
            </div>
            <button
              className="s-btn"
              onClick={handleVerifyOtp}
              disabled={!otp.every(digit => digit)}
            >
              Verify OTP
            </button>
          </div>
        )}
        {otpVerified && (
          <div className="verified-message">
            <p>OTP Verified!</p>
          </div>
        )}
        <div id="recaptcha"></div>
      </div>
    </div>
  );
};

export default Login;