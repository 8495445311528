import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import { FlipWords } from "../components/flip-words.tsx";
import Slider from "../components/Slider.js";
import Popup from "../components/popup.js";
import "./HomePage.css";
import Review from '../components/Review.js';
import BlogHome from "./BlogHome.js";
import Clientslider from "../components/Clientslider.js";
import Guidequestions from "../components/Guidequestions.js";
import Testimonialslider from "../components/Testimonialslider.js";
import FAQ from "../components/faq.js";


const HomePage = () => {
  const [items, setItems] = useState([]);
  // const [faqs, setFaqs] = useState([
  //   {
  //     question: "How many programmers does it take to screw a lightbulb?",
  //     answer:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
  //     open: true,
  //   },
  //   {
  //     question: "Who is the most awesome person?",
  //     answer: "You! The viewer!",
  //     open: false,
  //   },
  //   {
  //     question:
  //       "How many questions does it take to makes a succesful FAQ Page?",
  //     answer: "This many!",
  //     open: false,
  //   },
  // ]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  
  // const toggleFAQ = (index) => {
  //   setFaqs(
  //     faqs.map((faq, i) => {
  //       if (i === index) {
  //         faq.open = !faq.open;
  //       } else {
  //         faq.open = false;
  //       }

  //       return faq;
  //     })
  //   );
  // };

  return (    
    <div className="home-con">
      <div className="hero-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-banner-content">
                <h1>Smarter way to</h1>
                <img src="./Assets/hero-banner-img.png" alt="Hero Section" className="hero-banner-img img-fluid"></img>
                <div className="d-flex align-items-center mt-4">
                  <h1>Buy</h1>
                  <div className="hero-banner-animation">
                    <FlipWords words={["Loan", "Insurance", "Mediclaim"]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="about-img">
                <div className="select-product">
                  <p>Select product to compare quotes:</p>
                  <ul className="product-icons">
                    <li><a href=""><i className="fa-solid fa-money-bill"></i></a></li>
                    <li><a href=""><i className="fa-solid fa-heart-circle-plus"></i></a></li>
                    <li><a href=""><i className="fa-solid fa-capsules"></i></a></li>
                    <li><a href=""><i className="fa-solid fa-car-burst"></i></a></li>
                  </ul>
                </div>
                <img src="/Assets/family.png" alt="Family" className="family img-fluid" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about-content">
                <svg width="270" height="300" viewBox="0 0 206 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M 215 0 C 191 0 182 8 171 13 C 161 19 151 25 144 34 C 130 49 115 64 111 85 C 110 91 108 102 114 105 C 120 110 136 109 144 105 C 154 100 159 89 156 78 C 151 63 136 54 121 52 C 106 50 93 51 80 60 C 68 69 57 83 51 96 C 45 109 40 124 39 139 C 39 151 40 160 53 160 C 65 159 72 142 68 132 C 62 117 37 118 27 126 C 11 139 7 162 4 181 c -2 10 -2 21 -2 32 L -6 206 L 2 215 L 12 207" stroke="var(--font-color)" stroke-width="5" stroke-linecap="round"/>
                </svg>
                <h1>What will you do for you?</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                  dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen 
                  book. It has survived not only five centuries.</p>
                  <button className="btn" onClick={togglePopup}>Get Started</button>
                  <Popup isOpen={isPopupOpen} onClose={togglePopup} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="served-number">
        <div className="container">
          <div className="served-number-content">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="served-number-content-box">
                  <h1>Stat we served</h1>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                  the industry's standard dummy text ever since the 1500s</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="served-number-content-box text-center">
                      <h1>30M+</h1>
                      <p>Shoppers Served</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="served-number-content-box text-center">
                      <h1>$90B</h1>
                      <p>Life Insurance Sold</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="served-number-content-box text-center">
                      <h1>24/7</h1>
                      <p>Help From Experts</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="client-content">
                <h1>Our company work with</h1>
              </div>
            </div>
            <div className="col-lg-8">
              <Clientslider />
            </div>
          </div>
        </div>
      </div>
      <div className="trust">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="trust-content">
                <h1>Why Trust our Services?</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="trust-content-box">
                    <span>01</span>
                    <h3>Smart tools</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                      dummy text ever since the 1500s</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="trust-content-box">
                    <span>02</span>
                    <h3>Smart tools</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                      dummy text ever since the 1500s</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="trust-content-img">
                <img src="Assets/workers.png" alt="co-workers" className="img-fluid"></img>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="trust-content-box">
                <span>03</span>
                <h3>Smart tools</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                      dummy text ever since the 1500s</p>
              </div>
              <div className="trust-content-box">
                <span>04</span>
                <h3>Smart tools</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                      dummy text ever since the 1500s</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="guides">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="guides-content text-center">
                <h1>Explore our Guides</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                dummy text ever since the 1500s</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="guide-questions">
                <Guidequestions />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="guides-img">
                <img src="Assets/workers.png" alt="co-workers" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="testimonial-img">
                <img src="Assets/testimonial-img.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="testimonial-content">
                <h1>Join our millions of happy shoppers</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                dummy text ever since the 1500s</p>
                <div className="testmonial-slider">
                  <Testimonialslider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ />
      <div className="subscribe">
        <div className="container">
          <div className="subscribe-content">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="subscribe-img">
                  <img src="Assets/subscribe-img.png" className="img-fluid"></img>
                </div>
              </div>
              <div className="col-lg-6">
                <h1>Finished Scrolling? Start Saving</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard 
                dummy text ever since the 1500s</p>
                <a href="#" className="btn">Subscribe</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="blog-content text-center">
              <h1>Blog</h1>
            </div>
          </div>
          < BlogHome />
          </div>
        </div>
      </div>
      <Footer />
      
      {/* <div className="hero-sec-box">
        <h1 className="hero-sec-font">SMARTER WAY TO</h1>
        <img src="./Assets/Hero section img.png" alt="Hero Section"></img>
        <div className="hero-compartment">
          <div className="hero-sec-box-2">
            <h1 className="hero-sec-font">BUY</h1>
          </div>
          <div className="hero-sec-animation">
            <FlipWords words={["Loan", "Insurance", "Mediclaim"]} />
          </div>
        </div>
      </div>
      <div className="contai">
        <div className="img-con">
          <div className="select-product">
            <p>Select product to compare quotes:</p>
            <ul className="product-icons">
              <li><a href=""><i className="fa-solid fa-heart-circle-plus"></i></a></li>
              <li><a href=""><i className="fa-solid fa-heart-circle-plus"></i></a></li>
              <li><a href=""><i className="fa-solid fa-heart-circle-plus"></i></a></li>
              <li><a href=""><i className="fa-solid fa-heart-circle-plus"></i></a></li>
            </ul>
          </div>
          <img src="/Assets/family.png" alt="Family" />
        </div>
        <div className="half-width-container-2">
          <h1>What will you do for you?</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries,
          </p>
          <button className="btn" onClick={togglePopup}>Get Started</button>
          <Popup isOpen={isPopupOpen} onClose={togglePopup} />
        </div>
      </div>
      <div className="float-con">
        <div className="overlay">
          <div className="float-con-box-1">
            <h1>Stat we served</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div className="float-con-box-2">
            <h1>30M+</h1>
            <p>Shoppers Served</p>
          </div>
          <div className="float-con-box-2">
            <h1>$90B</h1>
            <p>Life Insurance Sold</p>
          </div>
          <div className="float-con-box-2">
            <h1>24/7</h1>
            <p>Help From Experts</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container-1">
          <h1>
            Our company
            <br /> works with
          </h1>
        </div>
        <div className="container-2">
          <Slider />
        </div>
      </div>
      <div className="cont">
        <div className="cont-1">
          <div className="cont-1-h">
            <h1>
              Why Trust
              <br />
              Our Services ?
            </h1>
          </div>
          <div className="cont-1-p">
            <span>01</span>
            <h6>Smart Tools</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div className="cont-1-p">
            <span>02</span>
            <h6>Smart</h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
        </div>
        <div className="cont-2">
          <div className="img-cont">
            <img src="Assets/workers.png" alt="co-workers"></img>
          </div>
          <div className="cont-2-vert">
            <div className="cont-2-p">
              <span>03</span>
              <h6>Smart Tools</h6>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div className="cont-2-p">
              <span>04</span>
              <h6>Smart Tools</h6>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="review-sec"> */}
        {/* <Review /> */}
      {/* </div> */}
      {/* <div className="main-faq">
        <div className="faq-flex">
          <div className="faq-1">
            <img src="/Assets/needtoknow.png"></img>
          </div>
          <div className="faq-2">
            <div className="faqs">
              <h1>Need to know</h1>
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div> */}
        {/* <BlogHome /> */}
      {/* </div> */}
      {/* <div> */}
        {/* <Footer /> */}
      {/* </div> */}
    </div>
  );
};

export default HomePage;
