const firebaseConfig = {
    apiKey: "AIzaSyCeB2koGtONHMAwxhdKhePXq8W9Unv7MnI",
    authDomain: "nanak-finserv.firebaseapp.com",
    projectId: "nanak-finserv",
    storageBucket: "nanak-finserv.appspot.com",
    messagingSenderId: "97479744155",
    appId: "1:97479744155:web:04688bf4fa0f6bd293843e",
    measurementId: "G-CEL1DFKVZC"
  };

  export default firebaseConfig;