import React from 'react';
import "./Breadcrumb.css";

const Breadcrumb = () => {
    return (
        <div className='breadcrumbs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3>Hello</h3>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Hello</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;