

import React, { useEffect, useState } from 'react';
import './Loani.css';
import Table from '../components/Table';
import { getAllConsumers, getAllLoanInterestedConsumer, getAllMediclaimCompany, getAllVerticle, getAllVerticleUser, updateLoanWorkingStatus } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';
import viewImage from './view.png';
import { useNavigate } from 'react-router';
import Popup from '../components/MediclaimCompany-popup';

const MediclaimCompany = () => {
    const addToast = useToaster()

    const [data, setData] = useState([]);
    const [verticle, setVerticle] = useState([]);
    const [verticleUser, setVerticleUser] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [heading, setHeading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const navigate = useNavigate()

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setEditIndex(null);
    };

    useEffect(() => {
        getAllMediclaimCompanyData();
    }, []);

    const handleViewClick = (item) => {
        // Add your view click handling logic here
        console.log('View clicked for item:', item);
        navigate(`/mediclaim/company/${item.mediclaim_company_id}`)
    };

    const getAllMediclaimCompanyData = async () => {
        const consumerData = await getAllMediclaimCompany();
        if (consumerData?.data && consumerData?.data?.length) {
            const processedData = consumerData?.data.map(item => ({
                ...item,
                view: <a><img className="building-view" src={viewImage} alt="View" style={{ cursor: 'pointer' }} onClick={() => handleViewClick(item)} /></a>
            }));
            setData(processedData);
        } else {
            setData([]);
        }
        setHeading([{ key: 'mediclaim_company_name', head: 'Name' },{ key: 'view', head: 'View' }]);
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setIsPopupOpen(true);
    };

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(direction);
    };

    const fetchApi = () => {
        getAllMediclaimCompanyData();
    };


    return (
        <div className='loani'>
            <div className='title-btn'>
                <h1>Mediclaim Company</h1>
                <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
                {isPopupOpen && <Popup
                    isOpen={isPopupOpen}
                    onClose={togglePopup}
                    fetchApi={fetchApi}
                    initialData={editIndex !== null ? data[editIndex] : null}
                  />}
            </div>
            {data.length > 0 && (
                <Table
                    headings={heading}
                    data={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    handleEdit={handleEdit}
                    handleSort={handleSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setItemsPerPage={setItemsPerPage}
                />
            )}
        </div>
    );
};

export default MediclaimCompany;
