import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, Navigate, Outlet, BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Bloglist1 from './pages/BlogList/bloglist1';
import Bloglist2 from './pages/BlogList/bloglist2';
import Bloglist3 from './pages/BlogList/bloglist3';
import Bloglist4 from './pages/BlogList/bloglist4';
import Bloglist5 from './pages/BlogList/bloglist5';
import Bloglist6 from './pages/BlogList/bloglist6';
import Login from './pages/Login';
import Dashboard from './dashboard/Dashboard';
import LifeInsurance from './dashboard/LifeInsurance';
import VehicleInsurance from './dashboard/VehicleInsurance';
import Mediclaim from './dashboard/Mediclaim';
import Loan from './dashboard/Loan';
import LoanI from './dashboard/Loani';
import LoanNI from './dashboard/Loanni';
import Consumer from './dashboard/Consumer';
import Builder from './dashboard/Builder';
import Unit from './dashboard/Unit';
import Scrollbar from './components/Scrollbar';
import CustomScrollbar from './CustomScrollbar';
import Popup from './components/popup';
import Sidebar from './components/Sidebar';
import DataComponent from './components/DataComponent';
import User from './dashboard/User';
import Cookies from 'js-cookie';
import Building from './dashboard/Building';
import Loandisbuss from './dashboard/Loandisbuss';
import { ToasterProvider } from './components/Toaster';
import MediclaimCompany from './dashboard/MediclaimCompany';
import MediclaimProduct from './dashboard/MedicliamProduct';

const directories = [
  { "Dashboard": [] },
  { "Loan": [] },
  { "LifeInsurance": [] },
  { "Mediclaim": [] },
  { "VehicleInsurance": [] },
  { "Consumer": [] },
  { "Builder": [] },
  { "User": [] },
  { "Login": [] }
];

const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [userData, setUserData] = useState(null);

  const dashboardPaths = [
    '/dashboard',
    '/lifeinsurance',
    '/vehicleinsurance',
    '/mediclaim',
    '/mediclaim/company',
    '/loan',
    '/loan/interested',
    '/loan/not-interested',
    '/loan/disburse',
    '/consumer',
    '/builder',
    '/unit',
    '/user'
  ];

  const pagesPaths = [
    '/',
    '/services',
    '/about',
    '/login',
    '/contact',
    '/blog',
    '/bloglist1',
    '/bloglist2',
    '/bloglist3',
    '/bloglist4',
    '/bloglist5',
    '/bloglist6'
  ];

  useEffect(() => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user')) || '';
    setUserData(user);
    if (user && user.category && user.category.length) {
      let data = user.category.map((item) => item['category.category_id']);
      setCategory(data);
    }
  }, []);

  const PrivateRoutes = () => {
    const token = Cookies.get('token');
    return token ? <Outlet /> : <Navigate to='/login' />;
  };

  const PrivateLoan = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    const categoryId = Cookies.get('category');
    return user && user.role_id == 1 || categoryId && categoryId.includes(2) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateMediclaim = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    const categoryId = Cookies.get('category');
    return user && user.role_id == 1 || categoryId && categoryId.includes(4) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateLife = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    const categoryId = Cookies.get('category');
    return user && user.role_id == 1 || categoryId && categoryId.includes(5) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateVehicle = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    const categoryId = Cookies.get('category');
    return user && user.role_id == 1 || categoryId && categoryId.includes(6) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateBuilder = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    return user && (user.role_id == 1 || user.role_id == 2) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateConsumer = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    return user && (user.role_id == 1 || user.role_id == 2) ? element : <Navigate to="/dashboard" />;
  };

  const PrivateRoleUser = ({ element }) => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    return user && (user.role_id == 1) ? element : <Navigate to="/dashboard" />;
  };

  const shouldHideNavbarAndScrollbar = location.pathname.includes('builder') || location.pathname.includes('unit') || location.pathname.includes('mediclaim') ? true : dashboardPaths.includes(location.pathname);
  const shouldHideSidebar = pagesPaths.includes(location.pathname);

  const isUserLoggedIn = () => {
    const user = Cookies.get('user') && JSON.parse(Cookies.get('user'));
    return !!user;
  };

  const PrivateRoute = ({ element }) => {
    const user = isUserLoggedIn();
    return user ? element : <Navigate to="/login" />;
  };

  return (
    <ToasterProvider>
      <div className="App">
        {!shouldHideNavbarAndScrollbar && <Scrollbar />}
        {!shouldHideNavbarAndScrollbar && <Navbar />}
        {!shouldHideSidebar && <Sidebar directories={directories} />}

        <div className="content">
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/bloglist1" element={<Bloglist1 />} />
            <Route path="/bloglist2" element={<Bloglist2 />} />
            <Route path="/bloglist3" element={<Bloglist3 />} />
            <Route path="/bloglist4" element={<Bloglist4 />} />
            <Route path="/bloglist5" element={<Bloglist5 />} />
            <Route path="/bloglist6" element={<Bloglist6 />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/consumer" element={<PrivateConsumer element={<Consumer />} />} />
            <Route path="/builder" element={<PrivateBuilder element={<Builder />} />} />
            <Route path="/unit/:id" element={<PrivateBuilder element={<Unit />} />} />
            <Route path="/builder/building" element={<PrivateBuilder element={<Building />} />} />
            <Route path="/data" element={<PrivateRoute element={<DataComponent />} />} />
            <Route path="/user" element={<PrivateRoleUser element={<User />} />} />
            <Route path="/loan" element={<PrivateLoan element={<Loan />} />} />
            <Route path="/loan/interested" element={<PrivateLoan element={<LoanI />} />} />
            <Route path="/loan/not-interested" element={<PrivateLoan element={<LoanNI />} />} />
            <Route path="/loan/disburse" element={<PrivateLoan element={<Loandisbuss />} />} />
            <Route path="/mediclaim" element={<PrivateMediclaim element={<Mediclaim />} />} />
            <Route path="/mediclaim/company" element={<PrivateMediclaim element={<MediclaimCompany />} />} />
            <Route path="/mediclaim/company/:id" element={<PrivateMediclaim element={<MediclaimProduct />} />} />
            <Route path="/lifeinsurance" element={<PrivateLife element={<LifeInsurance />} />} />
            <Route path="/vehicleinsurance" element={<PrivateVehicle element={<VehicleInsurance />} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Popup isOpen={location.pathname === ''} onClose={() => { }} />
          <CustomScrollbar />
        </div>
      </div>
    </ToasterProvider>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

export default App;
