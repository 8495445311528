import React from 'react';
import './Scrollbar.css';

const Scrollbar = () => {
  return (
    <div className="scroll-parent">
      <marquee>Hello World * contact us * mail us</marquee>
    </div>
  );
}

export default Scrollbar;
