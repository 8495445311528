import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Clientslider.css'; 
import { animate } from "framer-motion";

const Clientslider = () => {
    const options = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 2000,
        animateOut: 'slideOutUp',
        nav: false,
        dots: false
    };
    return (
        <OwlCarousel className='owl-theme' {...options}>
            <div className='item'>
                <img src="/Assets/slider1.png" alt="Logo 1" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider2.png" alt="Logo 2" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider3.png" alt="Logo 3" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider4.png" alt="Logo 4" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider5.png" alt="Logo 5" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider6.png" alt="Logo 6" className="img-fluid" />
            </div>
            <div className='item'>
                <img src="/Assets/slider7.png" alt="Logo 7" className="img-fluid" />
            </div>                
        </OwlCarousel>
    );
};

export default Clientslider;